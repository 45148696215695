// import { Controller } from "@hotwired/stimulus";
// import heic2any from "heic2any";

// export default class extends Controller {
//   static targets = ["canvas", "source"];

//   show() {
//     const reader = new FileReader();

//     reader.onload = function () {
//       this.canvasTarget.removeAttribute("hidden");

//       this.canvasTarget.src = reader.result;
//     }.bind(this)

//     reader.readAsDataURL(this.sourceTarget.files[0]);
//   }
// }
// app/javascript/controllers/image_preview_controller.js
import { Controller } from "@hotwired/stimulus";
import heic2any from "heic2any";

export default class extends Controller {
  static targets = ["fileInput", "preview"];

  preview(event) {
    const file = this.fileInputTarget.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async () => {
      try {
        if (file.type === "image/heic") {
          const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
          const url = URL.createObjectURL(convertedBlob);
          this.displayPreview(url);
        } else {
          this.displayPreview(reader.result);
        }
      } catch (error) {
        console.error("Error converting HEIC image:", error);
      }
    };

    if (file.type === "image/heic") {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsDataURL(file);
    }
  }

  displayPreview(url) {
    this.previewTarget.innerHTML = `<img src="${url}" alt="Image Preview" class="rounded-md object-cover size-24 mb-2">`;
  }
}
