import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-form"
export default class extends Controller {
  static targets = ["form"]

  connect() {
    window.addEventListener("beforeunload", (event) => {
      if (this.hasUnsavedChanges()) {
        event.preventDefault()
        event.returnValue = "You have unsaved changes. Are you sure you want to leave?"
        return "You have unsaved changes. Are you sure you want to leave?"
      }
    })
  }

  hasUnsavedChanges() {
    let form = this.formTarget
    let inputs = form.querySelectorAll("input, select, textarea")
    let changes = false

    inputs.forEach((input) => {
      if (input.defaultValue !== input.value) {
        changes = true
      }
    })

    return changes
  }

}
