import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  removeInstrument(event) {
    event.preventDefault()
    const instrumentField = event.target.closest('.instrument-fields')
    // If it's an existing record, mark for deletion instead of removing
    const destroyInput = instrumentField.querySelector('input[name*="_destroy"]')
    instrumentField.remove()
    // if (destroyInput) {
    //   destroyInput.value = "1"
    //   instrumentField.style.display = "none"
    // } else {
    //   // For new records, just remove from DOM
    //   instrumentField.remove()
    // }
  }

}
