import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "spinner", "signatureImage"];

  connect() {
    this.spinnerTarget.classList.add("hidden");
    let requiredFieldSelectors = 'input:required, select:required file:required';
    let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);
    requiredFields.forEach((field) => {
      field.addEventListener('focus', (event) => {
        // event.target.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        event.target.scrollIntoView();
      });
    });

    document.addEventListener('click', function(event) {
      if (event.target.name === 'commit') {
        // Blur (unfocus) any active input elements
        document.activeElement.blur();

        // Small delay to ensure the blur takes effect
        setTimeout(() => {
          event.target.click();
        }, 100);
      }
    });
  }

  submitStart() {
    this.spinnerTarget.classList.remove("hidden"); // Show the spinner
  }

  submitEnd() {
    this.spinnerTarget.classList.add("hidden"); // Hide the spinner
  }


  checkForm(event) {
    console.log("Checking form");
    let signatureImage = this.signatureImageTarget.value;
    let validSignature = this.isDataURL(signatureImage);

    if (!validSignature) {
      console.log("Form is incomplete");
      alert("Please sign the waiver");
      event.preventDefault();
    }
  }

  isDataURL(str) {
    const regex = /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?;base64,/;
    return regex.test(str);
  }
}
