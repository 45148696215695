import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input", "progress"];

  uploadFile() {
    Array.from(this.inputTarget.files).forEach((file) => {
      const upload = new DirectUpload(
        file,
        this.inputTarget.dataset.directUploadUrl,
        this // callback directUploadWillStoreFileWithXHR(request)
      );
      upload.create((error, blob) => {
        if (error) {
          Honeybadger.notify(error);
          console.log(error);
        } else {
          this.createHiddenBlobInput(blob);
          // mark original file field not required
          this.inputTarget.required = false;
          // clear the file input field so direct upload isn't being requested
          // twice.
          this.inputTarget.value = '';
        }
      });
    });
  }

  // add blob id to be submitted with the form
  createHiddenBlobInput(blob) {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", blob.signed_id);
    hiddenField.name = this.inputTarget.name;
    this.element.appendChild(hiddenField);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => {
      this.progressUpdate(event);
    });
  }

  progressUpdate(event) {
    const progress = (event.loaded / event.total) * 100;

    if (progress === 100) {
      // this.progressTarget.innerHTML = "Upload Complete";
      let changeButton = `
        <div class="btn btn-secondary btn-xs">
          Change Image
        </div>
      `
      // this.progressTarget.innerHTML = "Change Image";
      this.progressTarget.innerHTML = changeButton;
      // make this a link or something
      this.inputTarget.classList = "input-file";
    } else {
      let progressBar = `
        <div id="" class="mb-5 h-4 overflow-hidden rounded-full bg-gray-200">
          <div id="" class="h-4 animate-pulse rounded-full bg-gradient-to-r from-blue-500 to-green-500" style="width: ${progress}%"></div>
        </div>
      `
      this.progressTarget.innerHTML = progressBar
    }
  }
}
