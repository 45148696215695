import { Controller } from "@hotwired/stimulus"
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = [ "signature", "signatureImage", "clear", "submit", "info"]

  connect() {
    this.signaturePad = new SignaturePad(this.signatureTarget)
    let submitButton = document.getElementById("client-waiver-submit")
    this.signatureTarget.width = this.element.offsetWidth
    this.signaturePad.clear()
    // submitButton.disabled = true

    this.signaturePad.addEventListener("endStroke", () => {
      this.signatureImageTarget.value = this.signaturePad.toDataURL()
      // this.checkSignature()
    })

    this.clearTarget.addEventListener("click", () => {
      this.signaturePad.clear()
      this.signatureImageTarget.value = null
      // this.checkSignature()
    })
  }

  checkSignature() {
    // let submitButton = document.getElementById("client-waiver-submit")

    // if (this.isBase64(this.signatureImageTarget.value) && this.frontAndBackImages()) {
    //   debugger
    //   submitButton.disabled = false
    // } else {
    //   debugger
    //   submitButton.disabled = true
    // }
  }

  // isBase64(str) {
  //   try {
  //     return btoa(atob(str)) == str;
  //   } catch (err) {
  //     return false;
  //   }
  // }

  // frontAndBackImages() {
  //   let frontImage = Array.from(document.getElementsByName("waiver_submission[id_image_front]")).filter((el) => el.value)
  //   let backImage = Array.from(document.getElementsByName("waiver_submission[id_image_back]")).filter((el) => el.value)

  //   if (frontImage.length > 0 && backImage.length > 0) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // resizeCanvas() {
  //   const canvas = this.signatureTarget;
  //   const ratio =  Math.max(window.devicePixelRatio || 1, 1);
  //   canvas.width = canvas.offsetWidth * ratio;
  //   canvas.height = canvas.offsetHeight * ratio;
  //   canvas.getContext("2d").scale(ratio, ratio);
  //   signaturePad.clear();
  // }
}
