import { Controller } from "@hotwired/stimulus"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import AwsS3 from '@uppy/aws-s3'
import CryptoJS from 'crypto-js'

export default class extends Controller {
  static targets = ["dashboard", "input"]
  static values = {
    maxFileSize: { type: Number, default: 10 * 1024 * 1024 },
    allowedFileTypes: { type: Array, default: ['image/*', 'application/pdf'] },
    fieldName: { type: String, default: 'waiver_submittions[id_image_front]' }
  }

  connect() {
    this.uppy = new Uppy({
      autoProceed: true,
      retryDelays: [0, 5000, 10000],  // Retry after 0, 5 seconds, and 10 seconds
      allowMultipleUploadBatches: true,
      debug: true,
      onBeforeUpload: (files) => {
        // Log file information before upload
        Object.keys(files).forEach(fileId => {
          const file = files[fileId];
          console.log('Processing file:', {
            name: file.name,
            type: file.type,
            size: file.size,
            source: file.source,
            isRemote: file.isRemote
          });
        });
        return true;
      }
    })
    .use(Dashboard, {
      inline: true,
      target: this.dashboardTarget,
      height: 200,
      locale: {
        strings: {
          dropPasteFiles: '%{browseFiles}',
          browseFiles: 'Add Image',
        }
      }
    })
    .use(AwsS3, {
      endpoint: "/",
      getUploadParameters: async (file) => {
        try {
          console.log('Starting upload parameter request for:', file.name);
          
          // Calculate checksum first to catch any errors early
          const checksum = await this.calculateChecksumSafari(file);
          console.log('Checksum calculated:', checksum);

          const response = await fetch('/rails/active_storage/direct_uploads', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-CSRF-Token': this.getCSRFToken()
            },
            body: JSON.stringify({
              blob: {
                filename: file.name,
                content_type: file.type,
                byte_size: file.size,
                checksum: checksum
              }
            })
          });

          if (!response.ok) {
            const errorText = await response.text();
            console.error('Direct upload response error:', errorText);
            throw new Error(`HTTP ${response.status} - ${response.statusText}: ${errorText}`);
          }

          const blob = await response.json();
          console.log('Direct upload response:', blob);
          
          file.meta.signedId = blob.signed_id;

          return {
            method: 'PUT',
            url: blob.direct_upload.url,
            headers: {
              'Content-Type': file.type,
              ...blob.direct_upload.headers
            },
            fields: []
          };
        } catch (error) {
          console.error('Error in getUploadParameters:', error);
          throw error;
        }
      }
    });

    this.setupEventListeners();
  }

  // New Safari-optimized checksum calculation
  async calculateChecksumSafari(file) {
    return new Promise((resolve, reject) => {
      // Use smaller chunk size for Safari
      // const CHUNK_SIZE = 2 * 1024 * 1024; // 2MB chunks
      const CHUNK_SIZE = (navigator.userAgent.includes('Safari') && navigator.userAgent.includes('iPhone')) ? 1024 * 512 : 2 * 1024 * 1024; // 512KB for iOS Safari, 2MB for others
      const fileReader = new FileReader();
      let currentChunk = 0;
      const chunks = Math.ceil(file.size / CHUNK_SIZE);
      const wordArray = CryptoJS.lib.WordArray.create();

      console.log(`Processing file in ${chunks} chunks`);

      fileReader.onerror = () => {
        console.error('FileReader error:', fileReader.error);
        reject(new Error('FileReader error: ' + fileReader.error));
      };

      fileReader.onload = (e) => {
        try {
          const arrayBuffer = e.target.result;
          const array = new Uint8Array(arrayBuffer);
          wordArray.concat(CryptoJS.lib.WordArray.create(array));
          currentChunk++;

          console.log(`Processed chunk ${currentChunk} of ${chunks}`);

          if (currentChunk < chunks) {
            // Read next chunk
            loadNextChunk();
          } else {
            // All chunks have been read, calculate final MD5
            const md5 = CryptoJS.MD5(wordArray);
            const base64 = md5.toString(CryptoJS.enc.Base64);
            console.log('Final checksum calculated:', base64);
            resolve(base64);
          }
        } catch (error) {
          console.error('Error processing chunk:', error);
          reject(error);
        }
      };

      const loadNextChunk = () => {
        const start = currentChunk * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.data.slice(start, end);
        
        console.log(`Reading chunk from ${start} to ${end}`);
        fileReader.readAsArrayBuffer(chunk);
      };

      // Start reading the first chunk
      loadNextChunk();
    });
  }

  disconnect() {
    if (this.uppy) {
      this.uppy.close();
      this.uppy = null;
    }
  }

  setupEventListeners() {
    this.uppy.on('file-added', (file) => {
      console.log('File added:', file.name);
    });

    this.uppy.on('upload-progress', (file, progress) => {
      console.log('Upload progress:', {
        file: file.name,
        bytesUploaded: progress.bytesUploaded,
        bytesTotal: progress.bytesTotal
      });
    });

    this.uppy.on('upload-success', (file, response) => {
      console.log('Upload success:', {
        file: file.name,
        signedId: file.meta.signedId,
        response: response
      });
      this.updateHiddenInput(file.meta.signedId);
    });

    this.uppy.on('upload-error', (file, error, response) => {
      console.error('Upload error:', {
        file: file.name,
        error: error.message,
        response: response,
        userAgent: navigator.userAgent
      });
      
      Honeybadger.notify(error, {
        context: {
          file: file.name,
          fileType: file.type,
          fileSize: file.size,
          response: response,
          userAgent: navigator.userAgent
        }
      });
    });

    this.uppy.on('error', (error) => {
      console.error('Uppy error:', error);
    });
  }

  updateHiddenInput(signedId) {
    if (!signedId) {
      console.error('No signed ID found');
      return;
    }

    try {
      if (this.hasInputTarget) {
        this.inputTarget.value = signedId;
      } else {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = this.fieldNameValue;
        hiddenField.value = signedId;
        const form = this.element.closest('form');
        if (!form) throw new Error('No parent form found');
        form.appendChild(hiddenField);
      }
    } catch (error) {
      console.error('Error updating hidden input:', error);
      Honeybadger.notify(error);
    }
  }

  getCSRFToken() {
    const token = document.querySelector('meta[name="csrf-token"]')?.content;
    if (!token) {
      console.error('CSRF token not found');
    }
    return token;
  }
}
