import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "preview", "subject", "message"]

  connect() {
    this.updatePreview()
  }

  updatePreview(event) {
    const subject = this.subjectTarget.value
    const message = this.messageTarget.value
    if (subject != '') {
      document.getElementById('preview-subject').innerText = subject.slice(0, 30)
    }
    if (message != '') {
      document.getElementById('preview-message').innerText = message.slice(0, 30)
    }
  }
}
